<template>
	<div>
		<a-spin :spinning="loading">
			<div class="ft20 cl-black cl-main ftw500">订单管理</div>

			<div class="mt20">
				<div class="form-search-box ">
					<a-form layout="inline">
						<a-form-item label="订单编号">
							<a-input v-model="search.order_id" placeholder="请输入订单编号"></a-input>
						</a-form-item>

						<a-form-item label="订单类型">
							<a-select style="width: 160px" v-model="search.order_type">
								<a-select-option :value="0">
									全部类型
								</a-select-option>
								<a-select-option :value="'normal'">
									普通订单
								</a-select-option>
<!--								<a-select-option :value="'pin_tuan'">-->
<!--									拼团订单-->
<!--								</a-select-option>-->
<!--								<a-select-option :value="'flash_sale'">-->
<!--									秒杀订单-->
<!--								</a-select-option>-->
<!--								<a-select-option :value="'pre_sale'">-->
<!--									预售订单-->
<!--								</a-select-option>-->
							</a-select>
						</a-form-item>

						<a-form-item label="客户手机号">
							<a-input v-model="search.mobile" placeholder="请输入客户手机号"></a-input>
						</a-form-item>

						<a-form-item>
							<a-button @click="searchAct" type="primary">查询</a-button>
							<a-button @click="cancelAct" class="ml10">取消</a-button>
						</a-form-item>
					</a-form>
				</div>
			</div>

			<div class="mt20">
				<div class="bg-w pd30" style="min-height: 600px;">
					<div class="flex alcenter">
						<div class="">
							<a-radio-group v-model="search.status" button-style="solid" @change="changeStatus">
								<a-radio-button :value="0">全部</a-radio-button>
								<a-radio-button :value="1">待付款</a-radio-button>
								<a-radio-button :value="2">
									<div class="flex" style="position: relative;">待发货
										<div class="tag-num positon" v-if="deliver_num >0">{{deliver_num}}</div>
									</div>
								</a-radio-button>
								<a-radio-button :value="3">已发货</a-radio-button>
								<a-radio-button :value="4">
									<div class="flex" style="position: relative;">待到店自提
										<div class="tag-num positon" v-if="write_off_num > 0">{{write_off_num}}</div>
									</div>
								</a-radio-button>
								<a-radio-button :value="8">已完成</a-radio-button>
								<a-radio-button :value="-1">已取消</a-radio-button>
							</a-radio-group>
						</div>
					</div>

					<div class="mt30" v-if="datas.length>0">
						<div class="mall-goods_order-item" v-for="(order,index) in datas">
							<div class="mall-goods_order-item-header">
								<div class="flex">
									<div style="width: 85%;" class="flex alcenter ">
										<div class="mall-goods_order-item-tag ml20" style="background: #4772FF;"
											v-if="order.logistics_type==1">快递单</div>
										<div class="mall-goods_order-item-tag ml20" style="background: #00BA26;"
											v-if="order.logistics_type==2">自提单</div>
										<div class="ml8 ">
											<div class="flex alcenter">
												<div class="flex alcenter">
													<div class="ft14 ftw400 cl-info text-over4">订单编号：</div>
													<div class="ft14 ftw600 cl-main text-over4">{{order.order_id}}</div>
												</div>

												<div class="flex alcenter ml40" v-if="order.member">
													<div class="ft14 ftw400 cl-info text-over4">下单客户：</div>
													<div class="ft14 ftw600 cl-main text-over4">
														{{order.member.nick_name}} {{order.member.mobile}}</div>
												</div>

												<div class="flex alcenter ml40">
													<div class="ft14 ftw400 cl-info text-over4">订单类型：</div>
													<div class="ft14 ftw600 cl-theme text-over4">
														{{order.order_type_means}}</div>
												</div>

												<div class="flex alcenter ml40">
													<div class="ft14 ftw400 cl-info text-over4">支付方式：</div>
													<div class="ft14 ftw600 cl-main text-over4">{{order.pay_type_mean}}
													</div>
												</div>
											</div>
										</div>
									</div>
									<div style="width: 15%" class="flex center">操作</div>
								</div>
							</div>
							<div>
								<div class="flex">
									<div style="width: 40%;">
										<div>
											<div class="mall-goods_order-item-goods"
												v-for="(item,item_index) in order.skus">
												<div class="flex space alcenter">
													<div class="flex alcenter">
														<img v-if="item.goods_cover_img != null"
															:src="item.goods_cover_img"
															style="width: 60px;height: 60px;" />
													
														<div class="ml10">
															<div class="ft14 ftw500 cl-info" style="width: 60%;">
																<span class="mall-goods_order-item-goods tag"
																	v-if="item.goods_type=='pre_sale'">
																	付款{{item.yu_days}}日后发货
																</span>
																<span class="mall-goods_order-item-goods tag"
																	v-if="item.goods_type=='pin_tuan'">
																	{{item.group_size}}人团
																</span>
																{{item.goods_name}}
															</div>
															<div class="ft12 ftw400 cl-notice">{{item.goods_sku_name}}
															</div>
														</div>
													</div>
													<div class="flex alcenter ft14 ftw500 cl-info">
														<div>¥{{item.goods_price}}</div>
														<div class="ml8">x{{item.goods_num}}</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div style="width: 60%;" class="flex">
										<div class="flex alcenter center"
											style="width: 25%;border-bottom: 1px solid #EBEDF5;border-right: 1px solid #EBEDF5;">
											<div class="ft12 ftw400 cl-info">
												<div class="flex alcenter center">
													<div class="text-center">合计：¥{{order.total_price}}</div>
													<div class="text-center ml15" v-if="order.logistics_type==1">
														运费：¥{{order.freight}}</div>
												</div>
												<div class="mt5 text-center" v-if="order.coupon_money > 0">
													优惠券抵扣：¥{{order.coupon_money}}</div>
												<div class="mt5 text-center" v-if="order.integral_balance > 0">
													积分抵扣：¥{{order.integral_balance}}</div>
												<div class="mt5 text-center"
													v-if="order.pay_type=='offline' && order.youhui_balance > 0">
													优惠金额：¥{{order.youhui_balance}}</div>
												<div class="ft14 ftw500 cl-black mt5 text-center">
													实付金额：¥{{order.need_pay}}</div>
											</div>
										</div>
										<div class="flex alcenter center"
											style="width: 25%;border-bottom: 1px solid #EBEDF5;border-right: 1px solid #EBEDF5;">
											<div class="ft12 ftw400 cl-info">
												<div>
													<div class="text-center">下单时间</div>
													<div class="mt4 text-center">{{order.add_time_format}}</div>
												</div>
												<div class="mt12">
													<div class=" text-center">支付时间</div>
													<div class="ft14 ftw500  mt4 text-center">{{order.pay_time_format}}
													</div>
												</div>
											</div>
										</div>
										<div class="flex alcenter center"
											style="width: 25%;border-bottom: 1px solid #EBEDF5;border-right: 1px solid #EBEDF5;">
											<div class="ft14 ftw500 cl-info">
												{{order.status_means}}
											</div>
										</div>
										<div class="flex alcenter center"
											style="width: 25%;border-bottom: 1px solid #EBEDF5;border-right: 1px solid #EBEDF5;">
											<div class="ft14 ftw500 cl-info">
												<a-dropdown placement="bottomRight">
													<span class="more-act">
														<i class="iconfont iconmore_gray"></i>
													</span>
													<a-menu slot="overlay">
														<a-menu-item>
															<a class="menu-act" href="javascript:;" @click="see(index)">
																<i class="iconfont ft14 iconedit"></i>
																<span class="ml10">查看订单</span>
															</a>
														</a-menu-item>
														<a-menu-item v-if="order.logistics_type==1 && order.status==2">
															<a class="menu-act" href="javascript:;" @click="deliverGoodsAct(index)">
																<i class="iconfont ft14 icondelivery"></i>
																<span class="ml10">确认发货</span>
															</a>
														</a-menu-item>
														<a-menu-item v-if="order.logistics_type==1 && order.status==3">
															<a class="menu-act" href="javascript:;" @click="overGoodsAct(index)">
																<i class="iconfont ft14 icondelivery"></i>
																<span class="ml10">订单完成</span>
															</a>
														</a-menu-item>
														<a-menu-item v-if="order.logistics_type==2 && order.status==4">
															<a class="menu-act" href="javascript:;" @click="writeOffAct(index)">
																<i class="iconfont ft14 iconhexiao1"></i>
																<span class="ml10">确认核销</span>
															</a>
														</a-menu-item>
													</a-menu>
												</a-dropdown>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

					</div>

					<div class="mt30 flex center" v-else>
						<a-empty />
					</div>

					<div class="flex center mt20">
						<a-pagination @change="pageChange" :total="pagination.total" v-model="pagination.current"
							:pageSize="pagination.pageSize" show-less-items />
					</div>
				</div>
			</div>
		</a-spin>
	</div>

</template>

<script>
	import {
		listMixin
	} from '../../common/mixin/list.js';
	export default {
		mixins: [listMixin],
		data() {
			return {
				loading: false,
        currentPage:Number(sessionStorage.getItem('orderCurrentPage')) || 1,
        pagination: {
          current: Number(sessionStorage.getItem('orderCurrentPage')) || 1, // 默认当前页数
          pageSize: 10, //每页中显示10条数据
          total: 0,
          onChange: (current) => {
            this.currentPage = current;
          }
        },
				search: {
					order_id: '',
					order_type: 0,
					status: 0,
					mobile: '',
				},
				datas:[],
				deliver_num:0,
				write_off_num:0,
			}
		},
		methods: {
			getLists() {
				if (this.loading == true) return;
				this.loading = true;
				this.$http.api('admin/getGoodsOrderList', {
					order_id: this.search.order_id,
					order_type: this.search.order_type,
					status: this.search.status,
					mobile: this.search.mobile,
					limit: this.pagination.pageSize,
					page: this.pagination.current,
				}).then(res => {
					this.deliver_num=res.deliver_num;
					this.write_off_num=res.write_off_num;
					this.pagination.total = res.total;
					this.datas = res.list;
					this.loading = false;
				}).catch(res => {
					console.log(res);
					this.loading = false;
				})
			},
			overGoodsAct(index){
				this.$confirm({
					title:'确认完成这个订单吗？',
					okText:"确定",
					okType:"danger",
					cancelText:"取消",
					onOk : ()=>{
						return new Promise((resolve,reject)=>{
							this.$http.api('admin/overGoodsOrder',{
								order_id:this.datas[index].order_id,
							}).then(res=>{
								this.$message.success('完成成功');
								this.getLists();
							}).catch(res=>{
								console.log(res);
							}).finally(()=>{
								resolve();
							})
						})
					}
				})
			},
			deliverGoodsAct(index){
				this.$confirm({
					title:'确认发货这个订单吗？',
					okText:"确定",
					okType:"danger",
					cancelText:"取消",
					onOk : ()=>{
						return new Promise((resolve,reject)=>{
							this.$http.api('admin/deliverGoodsOrder',{
								order_id:this.datas[index].order_id,
							}).then(res=>{
								this.$message.success('发货成功');
								this.getLists();
							}).catch(res=>{
								console.log(res);
							}).finally(()=>{
								resolve();
							})
						})
					}
				})
			},
			
			writeOffAct(index){
				this.$confirm({
					title:'确定核销这个订单吗？',
					okText:"确定",
					okType:"danger",
					cancelText:"取消",
					onOk : ()=>{
						return new Promise((resolve,reject)=>{
							this.$http.api('admin/writeOffOrder',{
								order_id:this.datas[index].order_id,
							}).then(res=>{
								this.$message.success('核销成功');
								this.getLists();
							}).catch(res=>{
								console.log(res);
							}).finally(()=>{
								resolve();
							})
						})
					}
				})
			},

			changeStatus() {
				this.pagination.current = 1;
				this.getLists();
			},


			see(index) {
        sessionStorage.setItem('orderCurrentPage',this.currentPage);
				this.$router.push('/mall/order/detail?id=' + this.datas[index].order_id);
			},

			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
        sessionStorage.setItem('orderCurrentPage',pagination.current);
				this.getLists();
			},

		}
	}
</script>

<style>
	.mall-goods_order-item {
		width: 100%;
		margin-bottom: 20px;
	}

	.mall-goods_order-item-header {
		width: 100%;
		height: 50px;
		background: #F9F9F9;
		border: 1px solid #EBEDF5;
		line-height: 50px;
	}

	.mall-goods_order-item-tag {
		padding: 1px 8px;
		border-radius: 2px;

		font-size: 12px;
		font-weight: 500;
		color: #FFFFFF;
		line-height: 16px;
	}

	.mall-goods_order-item-goods {
		padding: 30px 20px;
		border: 1px solid #EBEDF5;
		border-top: none;
	}

	.mall-goods_order-item-goods.tag {
		padding: 1px 4px;
		background: rgba(71, 114, 255, 0.1);
		border-radius: 2px;

		font-size: 12px;
		font-weight: 500;
		color: #4772FF;
	}
	
	.tag-num .position{
		position: absolute;
		top:0;
		left:0;
	}
</style>
